<template>
    <div class="NotificationListItem pa-5">
        <div class="NotificationListItem-NotificationListItem">
            <h3>{{ notification.title | capitalizeFirstLetter }}</h3>
        </div>
        <div class="NotificationListItem-Timestamp">
            {{ convertTimeStampToDate(notification.publishedTime) }}
        </div>
        <div
            class="NotificationListItem-Content preserveEnters"
            v-html="$options.filters.santizeHTML(notification.content)"
        ></div>
    </div>
</template>

<script>
// --- State ---
import { mapState } from 'vuex';

import dayjs from 'dayjs';

export default {
    props: {
        notification: {
            required: true,
            type: Object
        }
    },
    computed: {
        ...mapState('auth', ['currentUserData'])
    },
    methods: {
        convertTimeStampToDate(date) {
            return dayjs(date).format('DD-MM-YYYY HH:mm');
        }
    }
};
</script>

<style lang="scss" scoped>
.NotificationListItem {
    background-color: white;
    border-left: 3px solid $color-primary !important;
    border-bottom-right-radius: 15px;

    &-Title {
    }

    &-Timestamp {
        font-size: 0.8em;
        font-style: italic;
        margin-bottom: 10px;
    }

    &-Content {
    }
}
</style>
