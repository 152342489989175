<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template></page-header
            >
        </template>
        <template #content>
            <template v-if="hasUserNotifications">
                <notifications-list :notifications="notifications" />
            </template>
            <v-card v-else class="emptyNotifications">
                <h3>{{ $t('notifications.empty_state') }}</h3>
            </v-card>
            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
// --- State ---
import { mapState } from 'vuex';
import ViewLayout from '@/components/ViewLayout.vue';
import Loader from '@/components/Loader';
import PageHeader from '@/components/PageHeader';

import { getAllNotifications, setNotificationsSeen } from '@/services/notifications';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import NotificationsList from '@/components/NotificationsList.vue';
import { reportError } from '@/helpers/logging';
import { mapFields } from 'vuex-map-fields';

export default {
    components: {
        ViewLayout,
        Loader,
        NotificationsList,
        PageHeader
    },
    data() {
        return {
            loading: false,
            notifications: []
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapFields('notifications', ['hasNewNotification']),
        hasUserNotifications() {
            return this.notifications.length > 0;
        }
    },

    mounted() {
        setNotificationsSeen()
            .then(() => {
                this.hasNewNotification = false;
            })
            .catch((e) => {
                reportError(e);
            });
    },

    async created() {
        try {
            this.loading = false;
        } catch (error) {
            apiErrorAndDisplay.call(this, error);
        } finally {
            this.loading = false;
        }
        this.notifications = await getAllNotifications();
    }
};
</script>

<style lang="scss" scoped>
.emptyNotifications {
    padding: 20px;
    text-align: center;
}
</style>
