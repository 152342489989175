<template>
    <div class="NotificationList">
        <div v-for="notification of notifications" :key="notification.id" class="NotificationList-Item">
            <notifications-list-item :notification="notification" />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import NotificationsListItem from './NotificationsListItem.vue';

export default {
    components: { NotificationsListItem },
    props: {
        notifications: {
            required: true,
            type: Array
        }
    },
    methods: {
        convertTimeStampToDate(date) {
            return dayjs(date).format('DD-MM-YYYY HH:mm');
        }
    }
};
</script>

<style lang="scss" scoped>
.NotificationList {
    &-Item {
        margin-bottom: 15px;
    }
}
</style>
